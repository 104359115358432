import React from "react"
import ContactUs from "../../images/ContactUs.png"

const ContactUsForm = () => {
  return (
    <div className="py-8 px-4 lg:px-24 lg:py-16 text-black">
      <div className="text-center lg:text-left mb-6 text-2xl font-semibold">
        Send us a message
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="flex-1 flex lg:p-4">
          <img
            className="w-full h-auto self-center"
            alt="contact us"
            src={ContactUs}
          />
        </div>
        <div className="flex-1 lg:ml-24">
          <form
            className="flex flex-col text-base"
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
            name="contact"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <label className="mt-2">
              Name <br />
              <input
                className="border border-light-gray focus:outline-none focus:border-primary-blue rounded-md w-full h-10 mt-1 px-4"
                type="text"
                name="name"
                id="name"
              />
            </label>
            <label className="mt-2">
              Email <br />
              <input
                className="border border-light-gray focus:outline-none focus:border-primary-blue rounded-md w-full h-10 mt-1 px-4"
                type="email"
                name="email"
                id="email"
              />
            </label>
            <label className="mt-2">
              Subject <br />
              <input
                className="border border-light-gray focus:outline-none focus:border-primary-blue rounded-md w-full h-10 mt-1 px-4"
                type="text"
                name="subject"
                id="subject"
              />
            </label>
            <label className="mt-2">
              Message <br />
              <textarea
                className="border border-light-gray focus:outline-none focus:border-primary-blue rounded-md w-full mt-1 p-4"
                name="message"
                id="message"
                rows="5"
              />
            </label>
            <button
              className="bg-primary-blue p-2 px-8 rounded-full text-white mt-2 focus:outline-none lg:w-32"
              value="Submit"
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ContactUsForm
