import React from "react"

const ContactUsBanner = () => {
  return (
    <div className="text-center justify-center bg-primary-off-white py-16 px-4 lg:px-56">
      <div className="text-3xl font-bold mb-12">Get in touch with us</div>
      <div className="text-lg  mb-12">
        Want to learn more about our product or have any question or feedback,
        or want to explore ways to work together? Whatever it is, send us a
        message! We would love to hear from you!
      </div>
    </div>
  )
}

export default ContactUsBanner
